import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

declare var jQuery;

@Component({
  selector: 'app-soft-token-request-success',
  templateUrl: './soft-token-request-success.component.html',
  styleUrls: ['./soft-token-request-success.component.scss']
})
export class SoftTokenRequestSuccessComponent implements OnInit, OnDestroy {

  token: any;
  device: string;

  qrCodeImage: any;

  rsaInstallInstructionsSelected = false;

  private readonly imageType: string = 'data:image/PNG;base64,';

  constructor(private router: Router, private domSanitizer: DomSanitizer) {
    const extras = router.getCurrentNavigation().extras.state || {};
    this.token = extras.token;
    this.device = extras.device;

    if (!this.token) {
      router.navigate(['/']);
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scroll(0, 0);
    });
  }

  ngOnInit(): void {
    jQuery('.portal-content').addClass('wide');
    this.qrCodeImage = this.domSanitizer.bypassSecurityTrustUrl(this.imageType + this.token.qrCode);

    window.setTimeout(() => {
      jQuery('#successTxt').focus();
    }, 0);
  }

  activateToken() {
    window.open(this.token.launcherUrl, '_blank');
  }

  ngOnDestroy() {
    jQuery('.portal-content').removeClass('wide');
  }

  showRsaInstallInstructions(): boolean {
    return this.rsaInstallInstructionsSelected;
  }

  toggleRsaInstallInstructions() {
    this.rsaInstallInstructionsSelected = !this.rsaInstallInstructionsSelected;
  }

  copyLink() {
    const el = document.createElement('textarea');
    el.value = this.token.launcherUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}
