import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AppValidators} from '../app-validators.service';
import {LoadingMaskService} from '../loading-mask.service';
import {AlertService} from '../alert.service';
import {PortalService} from '../portal.service';
import {EncryptService} from '../encrypt.service';
import {TooltipService} from "../tooltip.service";

declare var jQuery;

@Component({
  selector: 'app-soft-token-request',
  templateUrl: './soft-token-request.component.html',
  styleUrls: ['./soft-token-request.component.scss']
})
export class SoftTokenRequestComponent implements OnInit {

  requestForm: FormGroup;

  MIN_PIN_DIGITS = 6;
  MAX_PIN_DIGITS = 8;

  DOMAINS: [any];
  encKey: string;

  constructor(private formBuilder: FormBuilder, private router: Router,
              private appValidators: AppValidators, private loadingMask: LoadingMaskService,
              private alertService: AlertService, private service: PortalService,
              private encryptService: EncryptService,
              private tooltipService: TooltipService) {

    this.requestForm = this.formBuilder.group({
      user: new FormGroup({
        sid: new FormControl('', [
          Validators.required
        ]),
        password: new FormControl('', [
          Validators.required
        ]),
        domain: new FormControl('', [
          Validators.required
        ]),
        voiceAccessCode: new FormControl('', [
          Validators.required
        ]),
        mobileAccessCode: new FormControl('', [
          Validators.required
        ])
      }),
      deviceType: new FormControl('', [
        Validators.required
      ]),
      initialPin: new FormControl('', [
        Validators.required,
        Validators.minLength(this.MIN_PIN_DIGITS),
        Validators.maxLength(this.MAX_PIN_DIGITS),
        Validators.pattern(/^[1-9]\d*/)
      ]),
      confirmPin: new FormControl('', [
        Validators.required
      ])
    }, {
      validators: [appValidators.matchingPasswords('initialPin', 'confirmPin')]
    });
  }

  ngOnInit(): void {
    this.DOMAINS = this.service.getDomains();
    this.encKey = this.service.getEncKey();

    window.setTimeout(() => {
      jQuery('#sid').focus();
    }, 0);
  }

  ngAfterViewInit(): void {
    this.tooltipService.init();
  }

  ngOnDestroy(): void {
    this.tooltipService.destroy();
  }

  submitRequest(event: any) {
    event.preventDefault();

    this.requestForm.markAllAsTouched();

    if (this.requestForm.invalid) {
      this.alertService.alert('There are some fields with missing or invalid values, please correct them before you submit.');
      return;
    }

    this.alertService.hide();
    this.loadingMask.show();

    let req = this.requestForm.getRawValue();

    req.initialPin = this.encryptService.doEncrypt(req.initialPin,this.encKey);
    req.confirmPin = this.encryptService.doEncrypt(req.confirmPin,this.encKey);
    req.user.password = this.encryptService.doEncrypt(req.user.password,this.encKey);

/*    this.requestForm.value.initialPin = this.encryptService.encrypt(this.requestForm.value.initialPin,this.encKey);
    this.requestForm.value.confirmPin = this.encryptService.encrypt(this.requestForm.value.confirmPin,this.encKey);
    this.requestForm.value.user.password = this.encryptService.encrypt(this.requestForm.value.user.password,this.encKey);*/

    // TODO submit request here...
    this.service.requestSoftToken(req).subscribe((result) => {
      this.loadingMask.hide();

      if (result.errorCode !== 200) {
        this.alertService.alert(null, {code: result.rootCauseCode, ref: result.requestId});
        return;
      }

      // console.log(result);
      const token = result.result || {};
      this.router.navigate(['/assistance/soft-token-request/success'], {state: {token, device: this.requestForm.get('deviceType').value}});
    }, error => {
      console.log(error);
      this.alertService.alert(error.error.errorMessage, {code: error.error.rootCauseCode, ref: error.error.requestId});
      this.loadingMask.hide();
    });
  }

  forceNumbersOnly(event: any) {
    const input = event.target.attributes.getNamedItem('ng-reflect-name').value;
    this.requestForm.get(input).setValue(event.target.value.replace(/[^0-9]+/g, ''), { emitEvent: false });
  }

  get sid(): AbstractControl {
    return this.requestForm.get('user').get('sid');
  }

  get password(): AbstractControl {
    return this.requestForm.get('user').get('password');
  }

  get mobileAccessCode(): AbstractControl {
    return this.requestForm.get('user').get('mobileAccessCode');
  }

  get voiceAccessCode(): AbstractControl {
    return this.requestForm.get('user').get('voiceAccessCode');
  }

  get deviceType(): AbstractControl {
    return this.requestForm.get('deviceType');
  }

  get initialPin(): AbstractControl {
    return this.requestForm.get('initialPin');
  }

  get confirmPin(): AbstractControl {
    return this.requestForm.get('confirmPin');
  }

  get domain(): AbstractControl {
    return this.requestForm.get('user').get('domain');
  }
}
