<div class="d-flex flex-column justify-content-center" id="soft-token-request-success">
  <div class="tux-bg-success p-3 font-weight-bold" style="color: #fff" tabindex="-1" id="successTxt">
    <span class="fa fa-check icon-indicator-header"></span> Success!
  </div>

  <div class="container card-container">
    <div class="row">
      <h1 class="tux_lead mt-3">Your soft token is now ready!</h1>
    </div>

    <div class="row mt-4">
      <p class="lead">Next steps...</p>
    </div>

    <div class="row">
      <div class="col rsa-install-instructions">
        <div class="accordion">
          <div class="card">
            <div class="card-header">
              <h4 class="mb-0">
                <button type="button" [attr.aria-expanded]="showRsaInstallInstructions()" (click)="toggleRsaInstallInstructions()"><i
                  aria-hidden="true" class="icon caret-down" [ngClass]="{'active': showRsaInstallInstructions()}"></i>
                  <span class="bold">If you have not installed the RSA SecurID app on your registered device:</span> Click
                  here to view instructions.
                </button>
              </h4>
            </div>
            <div class="collapse" [ngClass]="{'show': showRsaInstallInstructions()}" [attr.aria-expanded]="showRsaInstallInstructions()">
              <div class="card-body">
                <ol>
                  <li>Go to the {{device === 'android' ? 'Google Play Store' : 'Apple App Store'}}.</li>
                  <li>
                    Search for the "RSA SecurID Software Token" app.<br>
                    <p class="mb-0">
                      For your convenience, you can also click on
                      <a *ngIf="device !== 'android'" href="https://apps.apple.com/us/app/rsa-securid-software-token/id318038618" target="_blank">this link</a>
                      <a *ngIf="device === 'android'" href="https://play.google.com/store/apps/details?id=com.rsa.securidapp" target="_blank">this link</a>
                      to get quick access to the RSA app.
                    </p>
                  </li>
                  <li>Download and install the app.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row flex-column">
      <p class="lead mt-4">Activate your token:</p>
      <div class="col">
        Open the RSA app to scan the QR code using your camera.
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-5">
        <img id="qr-code" alt="QR code" [src]="qrCodeImage">
      </div>

      <div class="col-12 col-md-7 pt-md-2 d-flex flex-column">
        <div class="mt-md-4">
          <p>
            If you are on your mobile device, tap on the link:
          </p>
          <p>
            <a href="{{token.launcherUrl}}" target="_blank" class="token-launcher-url">
              {{token.launcherUrl}}
            </a>
          </p>
        </div>

        <div class="mt-3">
          <p>
            <span class="bold">Please note:</span> This activation is valid until {{token.activationCodeExpiresOn | date}}
            and can only be used once.<br>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
