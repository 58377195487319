import { Injectable } from '@angular/core';

declare var jQuery;

@Injectable({
  providedIn: 'root'
})
export class LoadingMaskService {

  constructor() { }

  show(msg: string = 'Processing request, please wait...') {

    jQuery('#loading-msg').text(msg);
    jQuery('#loading-mask').css('display', 'block');

  }

  hide() {
    jQuery('#loading-mask').css('display', 'none');
  }
}
