<div class="message_panel">
  <h2 class="page_title">Ooops, we cannot find the page.</h2>
  <p class="page_description">
    If this is an error, please <a href="mailto:SecurID_Engineering_Team@restricted.jpmchase.com">contact SecurID
    team</a> for guidance.
  </p>
</div>
<div class="svg-wrapper">
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="-427.2 -198.2 874.3 416.4"
       enable-background="new -427.2 -198.2 874.3 416.4">
    <g id="Buildings">
      <path opacity="0.7" fill="#FFFFFF" enable-background="new    " d="M390.5,107.1v13h-8V80.7V50.1h-9v-18h-14V7h-11v25h-13v18h-10
 v30.6v39.4h-7v-18h-13v-27h7.3c0-16-14.3-31.2-30.3-33.2V28.1h-6v13.8c-15,1.2-31.2,14.2-31.2,33.2h6.2v27h-12v8.5h-52.3V-9h-7v-16
 h-11v16h-47v137.2h-246.6V40.1h-11v-21h-21v-29h-8v29h-20v21h-12v55h-21.7V83.5h-7.2v11.6h-4.3V76.3h-7.2v18.8h-10.6V9.7h-23.1
 V-7.7H-311V9.7h-7.2v41.9h-56.6v58.9h-52.3v104.5h52.3h56.4h65.6h5.3h51.2h7.2h59h5.7h246.7h65h52.3h79h72h50v-108H390.5z
 M-404,191.9h-17.4v-4.4h17.4V191.9z M-404,180.2h-17.4v-4.3h17.4V180.2z M-404,168.6h-17.4v-4.4h17.4V168.6z M-404,157h-17.4v-4.4
 h17.4V157z M-404,145.4h-17.4V141h17.4V145.4z M-404,133.8h-17.4v-4.4h17.4V133.8z M-404,122.2h-17.4v-4.4h17.4V122.2z
 M-380.6,191.9h-16.4v-4.4h16.3v4.4H-380.6z M-380.6,180.2h-16.4v-4.3h16.3v4.3H-380.6z M-380.6,168.6h-16.4v-4.4h16.3v4.4H-380.6z
 M-380.6,157h-16.4v-4.4h16.3v4.4H-380.6z M-380.6,145.4h-16.4V141h16.3v4.4H-380.6z M-380.6,133.8h-16.4v-4.4h16.3v4.4H-380.6z
 M-380.6,122.2h-16.4v-4.4h16.3v4.4H-380.6z M-363.3,190.5h-7.2v-13h7.2V190.5z M-363.3,161.6h-7.2v-10.1h7.2V161.6z M-363.3,135.6
 h-7.2V124h7.2V135.6z M-363.3,108.1h-7.2V96.5h7.2V108.1z M-363.3,80.6h-7.2V70.5h7.2V80.6z M-353.1,190.5h-5.8v-13h5.8V190.5z
 M-353.1,161.6h-5.8v-10.1h5.8V161.6z M-353.1,122.5h-5.8v-11.6h5.8V122.5z M-353.1,95.1h-5.8V83.5h5.8V95.1z M-343,190.5h-5.8v-13
 h5.8V190.5z M-343,161.6h-5.8v-10.1h5.8V161.6z M-343,135.6h-5.8V124h5.8V135.6z M-343,108.1h-5.8V96.5h5.8V108.1z M-343,80.6h-5.8
 V70.5h5.8V80.6z M-332.9,190.5h-5.8v-13h5.8V190.5z M-332.9,150h-5.8v-11.6h5.8V150z M-332.9,122.5h-5.8v-11.6h5.8V122.5z
 M-332.9,108.1h-5.8V96.5h5.8V108.1z M-332.9,80.6h-5.8V70.5h5.8V80.6z M-322.8,190.5h-4.3v-13h4.3V190.5z M-322.8,150h-4.3v-11.6
 h4.3V150z M-322.8,122.5h-4.3v-11.6h4.3V122.5z M-322.8,95.1h-4.3V83.5h4.3V95.1z M-322.8,80.6h-4.3V70.5h4.3V80.6z M-301.1,189.1
 h-10.1v-10.2h10.1V189.1z M-301.1,174.6h-10.1v-10.1h10.1V174.6z M-301.1,145.7h-10.1v-10.1h10.1V145.7z M-301.1,131.2h-10.1v-10.1
 h10.1V131.2z M-301.1,116.7h-10.1v-10.1h10.1V116.7z M-301.1,102.3h-10.1V92.2h10.1V102.3z M-301.1,73.4h-10.1V63.3h10.1V73.4z
 M-301.1,58.9h-10.1V48.8h10.1V58.9z M-301.1,44.4h-10.1V34.3h10.1V44.4z M-286.6,174.6h-8.7v-10.1h8.7V174.6z M-286.6,160.1h-8.7
 V150h8.7V160.1z M-286.6,145.7h-8.7v-10.1h8.7V145.7z M-286.6,131.2h-8.7v-10.1h8.7V131.2z M-286.6,116.7h-8.7v-10.1h8.7V116.7z
 M-286.6,102.3h-8.7V92.2h8.7V102.3z M-286.6,87.8h-8.7V77.7h8.7V87.8z M-286.6,73.4h-8.7V63.3h8.7V73.4z M-286.6,44.4h-8.7V34.3
 h8.7V44.4z M-270.7,189.1h-8.7v-10.2h8.7V189.1z M-270.7,174.6h-8.7v-10.1h8.7V174.6z M-270.7,160.1h-8.7V150h8.7V160.1z
 M-270.7,145.7h-8.7v-10.1h8.7V145.7z M-270.7,116.7h-8.7v-10.1h8.7V116.7z M-270.7,102.3h-8.7V92.2h8.7V102.3z M-270.7,87.8h-8.7
 V77.7h8.7V87.8z M-270.7,73.4h-8.7V63.3h8.7V73.4z M-270.7,58.9h-8.7V48.8h8.7V58.9z M-270.7,44.4h-8.7V34.3h8.7V44.4z
 M-256.2,189.1h-8.7v-10.2h8.7V189.1z M-256.2,160.1h-8.7V150h8.7V160.1z M-256.2,145.7h-8.7v-10.1h8.7V145.7z M-256.2,131.2h-8.7
 v-10.1h8.7V131.2z M-256.2,116.7h-8.7v-10.1h8.7V116.7z M-256.2,102.3h-8.7V92.2h8.7V102.3z M-256.2,87.8h-8.7V77.7h8.7V87.8z
 M-256.2,73.4h-8.7V63.3h8.7V73.4z M-256.2,44.4h-8.7V34.3h8.7V44.4z M-218.1,205h-5.8v-7.2h5.8V205z M-218.1,194.8h-5.8v-7.2h5.8
 V194.8z M-218.1,184.7h-5.8v-7.2h5.8V184.7z M-218.1,174.6h-5.8v-7.2h5.8V174.6z M-218.1,164.5h-5.8v-7.2h5.8V164.5z M-218.1,154.4
 h-5.8v-7.2h5.8V154.4z M-218.1,144.2h-5.8V137h5.8V144.2z M-218.1,134.1h-5.8v-7.2h5.8V134.1z M-218.1,124h-5.8v-7.2h5.8V124z
 M-218.1,113.9h-5.8v-7.2h5.8V113.9z M-206.5,205h-5.8v-7.2h5.8V205z M-206.5,194.8h-5.8v-7.2h5.8V194.8z M-206.5,184.7h-5.8v-7.2
 h5.8V184.7z M-206.5,174.6h-5.8v-7.2h5.8V174.6z M-206.5,164.5h-5.8v-7.2h5.8V164.5z M-206.5,154.4h-5.8v-7.2h5.8V154.4z
 M-206.5,144.2h-5.8V137h5.8V144.2z M-206.5,134.1h-5.8v-7.2h5.8V134.1z M-206.5,124h-5.8v-7.2h5.8V124z M-206.5,113.9h-5.8v-7.2
 h5.8V113.9z M-156.4,131.1h-8v-16h8V131.1z M-156.4,108.1h-8v-16h8V108.1z M-144.4,131.1h-8v-16h8V131.1z M-144.4,108.1h-8v-16h8
 V108.1z M-130.4,131.1h-9v-16h9V131.1z M-130.4,108.1h-9v-16h9V108.1z M141.3,84.1h-13v-16h13V84.1z M141.3,42.1h-13v-16h13V42.1z
 M141.3,21.1h-13V2h13V21.1z M163.3,84.1h-17v-16h17V84.1z M163.3,63.1h-17v-16h17V63.1z M163.3,42.1h-17v-16h17V42.1z M163.3,21.1
 h-17V2h17V21.1z M182.3,63.1h-14v-16h14V63.1z M182.3,42.1h-14v-16h14V42.1z M210.5,191.9h-17.4v-4.4h17.4V191.9z M210.5,180.2
 h-17.4v-4.3h17.4V180.2z M210.5,168.6h-17.4v-4.4h17.4V168.6z M210.5,157h-17.4v-4.4h17.4V157z M210.5,145.4h-17.4V141h17.4V145.4z
 M210.5,133.8h-17.4v-4.4h17.4V133.8z M210.5,122.2h-17.4v-4.4h17.4V122.2z M233.7,191.9h-16.3v-4.4h16.3V191.9z M233.7,180.2
 h-16.3v-4.3h16.3V180.2z M233.7,168.6h-16.3v-4.4h16.3V168.6z M233.7,157h-16.3v-4.4h16.3V157z M233.7,145.4h-16.3V141h16.3V145.4z
 M233.7,133.8h-16.3v-4.4h16.3V133.8z M233.7,122.2h-16.3v-4.4h16.3V122.2z M266.5,182.1h-5v-22h5V182.1z M266.5,142.1h-5v-24h5
 V142.1z M266.5,101.1h-5v-23h5V101.1z M280.5,182.1h-4v-22h4V182.1z M280.5,142.1h-4v-24h4V142.1z M280.5,101.1h-4V79.8v-1.7h4
 V101.1z M295.5,182.1h-4v-22h4V182.1z M295.5,142.1h-4v-24h4V142.1z M295.5,101.1h-4v-23h4V101.1z M332.5,169.1h-7v-25.7v-6.3h7
 V169.1z M344.5,169.1h-6v-32h6V169.1z M344.5,120.1h-6v-26h6V120.1z M344.5,85.1h-6v-26h6V85.1z M356.5,169.1h-6v-32h6V169.1z
 M356.5,120.1h-6v-26h6V120.1z M356.5,85.1h-6v-26h6V85.1z M368.5,169.1h-6v-32h6V169.1z M368.5,120.1h-6v-26h6V120.1z M368.5,85.1
 h-6v-26h6V85.1z M380.5,169.1h-6v-32h6V169.1z M402.5,183.1h-6v-11h6V183.1z M402.5,166.1h-6v-12h6V166.1z M402.5,148.1h-6v-11h6
 V148.1z M402.5,131.1h-6v-11h6V131.1z M413.5,183.1h-6v-11h6V183.1z M413.5,148.1h-6v-11h6V148.1z M423.5,166.1h-6v-12h6V166.1z
 M423.5,148.1h-6v-11h6V148.1z M423.5,131.1h-6v-11h6V131.1z M434.5,183.1h-6v-11h6V183.1z M434.5,166.1h-6v-12h6V166.1z
 M434.5,131.1h-6v-11h6V131.1z"></path>
    </g>
    <g id="mac">
      <g>
        <path fill="#C6C6C6" d="M43.2,205.6c-2.4-2.7-3.2-10.3-3.5-15l0,0v-4h-0.1H9.6h-3h-29h-0.1c0,0,0,15-3.5,19
 c-3.5,4.1-9.3,6.4-9.3,6.4h42h3h43C52.7,212,46.8,209.7,43.2,205.6z"></path>
        <path fill="#DADADA"
              d="M-129.5-20.5c-5.5,0-10,4.5-10,10v170h298.1v-170c0-5.5-4.5-10-10-10C148.6-20.5-129.5-20.5-129.5-20.5z"></path>
        <path fill="#DADADA"
              d="M-139.5,158.6v17c0,6.1,4.9,11,11,11h276.1c6.1,0,11-4.9,11-11v-17H-139.5z"></path>
        <rect x="-125.5" y="-7.5" fill="#C6C6C6" width="270.1" height="153"></rect>
        <polygon fill="#CCCCCC" points="144.6,-7.5 144.6,145.6 -44.5,-7.5 		"></polygon>
        <path fill="#DADADA" d="M52.1,215.8h-86.8c-0.6,0-1.1-0.5-1.1-1.1v-1.6c0-0.6,0.5-1.1,1.1-1.1h86.8c0.6,0,1.1,0.5,1.1,1.1v1.6
 C53.2,215.3,52.7,215.8,52.1,215.8z"></path>
      </g>
    </g>
    <g id="cloud_x5F_small02" opacity="0.76">
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="144.5777" y1="-46.9634" x2="144.5777"
                      y2="-82.0717">
        <stop offset="0" style="stop-color: rgb(255, 255, 255); stop-opacity: 0;"></stop>
        <stop offset="1" style="stop-color: rgb(255, 255, 255);"></stop>
      </linearGradient>
      <path opacity="0.7" fill="url(#SVGID_1_)" enable-background="new    " d="M164.3-65.4c-0.6,0-1.2,0.1-1.7,0.2
 c0.1-0.5,0.1-1.1,0.1-1.6c0-8.4-6.8-15.3-15.3-15.3c-6.9,0-12.7,4.5-14.6,10.8c-1.4-0.5-2.9-0.9-4.5-0.9c-6.9,0-12.6,5.6-12.6,12.6
 S121.3-47,128.3-47h36.1c5,0,9.1-4.1,9.1-9.1C173.5-61.3,169.4-65.4,164.3-65.4z"></path>
      <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="144.5777" y1="-46.9634" x2="144.5777"
                      y2="-82.0717">
        <stop offset="0" style="stop-color: rgb(255, 255, 255); stop-opacity: 0;"></stop>
        <stop offset="1" style="stop-color: rgb(255, 255, 255);"></stop>
      </linearGradient>
      <path opacity="0.7" fill="url(#SVGID_2_)" enable-background="new    " d="M164.3-65.4c-0.6,0-1.2,0.1-1.7,0.2
 c0.1-0.5,0.1-1.1,0.1-1.6c0-8.4-6.8-15.3-15.3-15.3c-6.9,0-12.7,4.5-14.6,10.8c-1.4-0.5-2.9-0.9-4.5-0.9c-6.9,0-12.6,5.6-12.6,12.6
 S121.3-47,128.3-47h36.1c5,0,9.1-4.1,9.1-9.1C173.5-61.3,169.4-65.4,164.3-65.4z"></path>
    </g>
    <g id="cloud_x5F_small01" opacity="0.76">
      <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-92.9283" y1="-115.4796" x2="-92.9283"
                      y2="-159.1899">
        <stop offset="0" style="stop-color: rgb(255, 255, 255); stop-opacity: 0;"></stop>
        <stop offset="1" style="stop-color: rgb(255, 255, 255);"></stop>
      </linearGradient>
      <path opacity="0.7" fill="url(#SVGID_3_)" enable-background="new    " d="M-65-137.4c0.2-1,0.3-2,0.3-3.1
 c0-9.6-7.8-17.3-17.3-17.3c-4.1,0-7.9,1.4-10.9,3.9c-3.2-3.3-7.5-5.3-12.3-5.3c-9.4,0-17,7.6-17,17c0,0.9,0.1,1.7,0.2,2.6
 c-4.9,1.6-8.5,6.3-8.5,11.7c0,6.8,5.5,12.4,12.4,12.4h51.7c6.1,0,11-4.9,11-11C-55.4-132.1-59.6-136.7-65-137.4z"></path>
    </g>
    <g id="cloud_x5F_big01" opacity="0.76">
      <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-216.4575" y1="-32.1599" x2="-216.4575"
                      y2="-137.2848">
        <stop offset="0" style="stop-color: rgb(255, 255, 255); stop-opacity: 0;"></stop>
        <stop offset="1" style="stop-color: rgb(255, 255, 255);"></stop>
      </linearGradient>
      <path opacity="0.7" fill="url(#SVGID_4_)" enable-background="new    " d="M-121.2-62.1c0-16.6-13.4-30-30-30c-0.6,0-1.2,0-1.8,0.1
 c0.3-1.8,0.4-3.7,0.4-5.6c0-21.9-17.8-39.7-39.7-39.7c-17.6,0-32.5,11.4-37.7,27.2c-4.6-2.9-10.1-4.6-15.9-4.6
 c-16.6,0-30,13.4-30,30c0,0.6,0,1.2,0.1,1.8c-3-1.2-6.2-1.8-9.6-1.8c-14.5,0-26.3,11.8-26.3,26.3c0,13.9,10.8,25.3,24.5,26.2l0,0
 c0,0,137.2,0,137.7,0l0,0C-133.9-32.9-121.2-46-121.2-62.1z"></path>
    </g>
    <g id="cloud_x5F_big02" opacity="0.76">
      <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="361.6808" y1="-23.858" x2="361.6808"
                      y2="-105.5773">
        <stop offset="0" style="stop-color: rgb(255, 255, 255); stop-opacity: 0;"></stop>
        <stop offset="1" style="stop-color: rgb(255, 255, 255);"></stop>
      </linearGradient>
      <path opacity="0.7" fill="url(#SVGID_5_)" enable-background="new    " d="M429.5-59.3c-1.7,0-3.3,0.3-4.9,0.7c0-0.4,0-0.7,0-1.1
 c0-12.3-10-22.4-22.4-22.4c-2.4,0-4.7,0.4-6.8,1.1c-3.4-14.1-16.1-24.6-31.3-24.6c-13,0-24.2,7.7-29.3,18.8
 c-2.1-0.6-4.4-0.9-6.7-0.9c-12.9,0-23.5,9.5-25.4,21.8c-1.7-0.4-3.4-0.6-5.2-0.6c-11.8,0-21.3,9.6-21.3,21.3s9.6,21.3,21.3,21.3
 h131.8c9.8,0,17.8-8,17.8-17.8C447.3-51.3,439.3-59.3,429.5-59.3z"></path>
    </g>
    <g id="full_crane">
      <g id="billboard">
        <path fill="#FFFFFF" d="M-82.5-43v11.9h182.6V-43c0-5.4-4.4-9.7-9.7-9.7H-72.8C-78.1-52.7-82.5-48.3-82.5-43z M-39.7-46.8
 c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4s-5.4-2.4-5.4-5.4S-42.6-46.8-39.7-46.8z M-55.4-46.8c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4
 s-5.4-2.4-5.4-5.4S-58.4-46.8-55.4-46.8z M-70.7-46.8c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4s-5.4-2.4-5.4-5.4S-73.7-46.8-70.7-46.8
 z"></path>
        <path fill="#1CC6F5"
              d="M100.3,68.7v-96.7H-82.5v96.7c0,4.9,4.2,9.1,9.1,9.1H91.2C96.1,77.8,100.3,73.6,100.3,68.7z"></path>
        <path fill="#0E59A4" d="M103.2-30.1V-43c0-7-5.7-12.7-12.7-12.7H-72.8c-7,0-12.7,5.7-12.7,12.7v13.4v98.2c0,6.7,5.4,12.1,12.1,12.1
 H91.2c6.7,0,12.1-5.4,12.1-12.1v-98.2C103.3-29.7,103.2-29.9,103.2-30.1z M-73.4,77.8c-4.9,0-9.1-4.2-9.1-9.1v-96.7h182.7v96.7
 c0,4.9-4.2,9.1-9.1,9.1H-73.4z M90.5-52.7c5.4,0,9.7,4.4,9.7,9.7v11.9H-82.5V-43c0-5.4,4.4-9.7,9.7-9.7H90.5z"></path>
        <circle fill="#0E59A4" cx="-70.7" cy="-41.4" r="5.4"></circle>
        <circle fill="#0E59A4" cx="-55.4" cy="-41.4" r="5.4"></circle>
        <circle fill="#0E59A4" cx="-39.7" cy="-41.4" r="5.4"></circle>
        <g id="loading">
          <g>
            <path fill="#0E59A4" d="M29.4,22.9h-0.3h-6.7H22c-1,0.1-1.8,0.9-1.8,2s0.9,2,2,2h7.1c1-0.1,1.8-0.9,1.8-2
 C31.2,23.9,30.4,23,29.4,22.9z"></path>
            <path fill="#0E59A4" d="M19.6,16.7L19.6,16.7l4.8-4.8l0.3-0.3c0.7-0.8,0.6-2-0.1-2.7c-0.8-0.7-2-0.8-2.7-0.1l-0.2,0.2L17,13.7
 l-0.2,0.2c-0.7,0.8-0.6,2,0.1,2.7C17.6,17.5,18.8,17.5,19.6,16.7z"></path>
            <path fill="#0E59A4" d="M8.6,2.3c-1.1,0-1.9,0.8-2,1.9v0.3v6.6v0.3c0.1,1,0.9,1.8,2,1.8s2-0.9,2-2l0,0V4.4V4
 C10.5,3.1,9.7,2.3,8.6,2.3z"></path>
            <path fill="#0E59A4" enable-background="new    " d="M0.4,13.9L-4.4,9l-0.3-0.3c-0.8-0.7-2-0.6-2.7,0.1
 c-0.8,0.8-0.8,2-0.1,2.7l0.2,0.2l4.7,4.7l0.2,0.2c0.8,0.7,2,0.6,2.7-0.1C1.2,16,1.2,14.7,0.4,13.9L0.4,13.9z"></path>
            <path fill="#0E59A4" enable-background="new    " d="M-4.8,26.9c1-0.1,1.8-0.9,1.8-2s-0.9-2-2-2h-7.1
 c-1,0.1-1.8,0.9-1.8,2s0.8,1.9,1.9,2h0.3H-5H-4.8L-4.8,26.9z"></path>
            <path fill="#0E59A4" enable-background="new    " d="M-2.4,33.1L-2.4,33.1l-4.8,4.8l-0.3,0.3
 c-0.7,0.8-0.6,2,0.1,2.7c0.8,0.7,2,0.8,2.7,0.1l0.2-0.2l4.7-4.7l0.2-0.2c0.7-0.8,0.6-2-0.1-2.7C-0.4,32.3-1.6,32.3-2.4,33.1z"></path>
            <path fill="#0E59A4" enable-background="new    " d="M10.6,38.3c-0.1-1-0.9-1.8-2-1.8s-2,0.9-2,2l0,0v6.8v0.4
 c0.1,1,1,1.8,2,1.8c1.1,0,1.9-0.8,2-1.9v-0.3v-6.6V38.3z"></path>
            <path fill="#0E59A4" d="M24.4,37.9l-4.7-4.7L19.5,33c-0.8-0.7-2-0.6-2.7,0.1c-0.8,0.8-0.8,2,0,2.8l0,0l4.8,4.8l0.3,0.2
 c0.8,0.7,2,0.6,2.7-0.1c0.8-0.8,0.8-2,0.1-2.7L24.4,37.9z"></path>
          </g>
        </g>
      </g>
      <g>
        <polygon fill="none" points="4.8,-103.8 4.8,-119 -10.3,-103.8 	"></polygon>
        <polygon fill="none" points="28,-106 43,-121.1 28,-121.1 	"></polygon>
        <polygon fill="none" points="230.5,-133.6 216.9,-147.2 203.2,-133.6 216.8,-120 	"></polygon>
        <polygon fill="none" points="202.6,-161.6 202.6,-137.2 214.8,-149.4 	"></polygon>
        <polygon fill="none" points="25,-106 25,-121.1 10,-121.1 	"></polygon>
        <polygon fill="none" points="105.6,-106 105.6,-121.1 90.6,-121.1 	"></polygon>
        <polygon fill="none" points="65.3,-106 65.3,-121.1 50.3,-121.1 	"></polygon>
        <polygon fill="none" points="108.6,-106 123.7,-121.1 108.6,-121.1 	"></polygon>
        <polygon fill="none" points="68.3,-106 83.3,-121.1 68.3,-121.1 	"></polygon>
        <polygon fill="none" points="229.6,15.4 205.2,15.4 217.4,27.5 	"></polygon>
        <polygon fill="none" points="229.1,-53.2 205.7,-53.2 217.4,-41.5 	"></polygon>
        <polygon fill="none" points="193.9,-191.6 53.2,-124.1 126.7,-124.1 	"></polygon>
        <rect x="202.6" y="-95" fill="none" width="29.4" height="8.8"></rect>
        <polygon fill="none" points="217.4,-5.7 205.2,6.4 229.6,6.4 	"></polygon>
        <polygon fill="none" points="148.9,-121.1 148.9,-106 164,-121.1 	"></polygon>
        <polygon fill="none" points="203.3,-165.1 216.9,-151.5 230.5,-165.1 216.9,-178.7 	"></polygon>
        <polygon fill="none" points="231.1,-130 219,-117.8 231.1,-105.7 	"></polygon>
        <polygon fill="none" points="217.4,63.3 205.2,75.5 229.6,75.5 	"></polygon>
        <polygon fill="none" points="219,-149.4 231.1,-137.2 231.1,-161.5 	"></polygon>
        <polygon fill="none" points="202.6,-105.7 214.7,-117.8 202.6,-130 	"></polygon>
        <polygon fill="none" points="202.6,-168.7 214.7,-180.9 202.6,-193.1 	"></polygon>
        <path fill="none" d="M272.4-124.1h54.8l-117.4-66l23.5,23.6c0.5,0.2,0.8,0.8,0.8,1.3v61.4h17.5l19.8-19.9
 C271.6-123.9,272-124.1,272.4-124.1z"></path>
        <polygon fill="none" points="255.8,-103.8 270.9,-103.8 270.9,-118.9 	"></polygon>
        <polygon fill="none" points="204.9,-103.8 228.8,-103.8 216.8,-115.7 	"></polygon>
        <polygon fill="none" points="296.1,-103.8 311.2,-103.8 311.2,-118.9 	"></polygon>
        <polygon fill="none" points="273.9,-103.8 289.1,-103.8 273.9,-119 	"></polygon>
        <polygon fill="none" points="314.2,-103.8 329.4,-103.8 314.2,-119 	"></polygon>
        <path fill="none" d="M130.9-124.1h36.8c0.4,0,0.8,0.2,1.1,0.4l19.9,19.9h11v-89.3L130.9-124.1z"></path>
        <polygon fill="none" points="150.9,-103.8 156,-103.8 166.1,-103.8 166.1,-119 	"></polygon>
        <polygon fill="none" points="169.1,-103.8 184.4,-103.8 169.1,-119.1 	"></polygon>
        <polygon fill="none" points="291.1,-106 291.1,-121.1 276,-121.1 	"></polygon>
        <polygon fill="none" points="45.2,-103.8 45.2,-119 30,-103.8 	"></polygon>
        <polygon fill="none" points="88.5,-118.9 88.5,-103.8 103.6,-103.8 	"></polygon>
        <polygon fill="none" points="85.5,-103.8 85.5,-119 70.3,-103.8 	"></polygon>
        <polygon fill="none" points="7.8,-103.8 22.9,-103.8 7.8,-118.9 	"></polygon>
        <polygon fill="none" points="48.2,-118.9 48.2,-103.8 63.3,-103.8 	"></polygon>
        <polygon fill="none" points="145.9,-106 145.9,-121.1 130.9,-121.1 	"></polygon>
        <polygon fill="none" points="128.8,-118.9 128.8,-103.8 143.9,-103.8 	"></polygon>
        <polygon fill="none" points="294.1,-121.1 294.1,-106 309.1,-121.1 	"></polygon>
        <polygon fill="none" points="331.4,-106 331.4,-121.1 316.3,-121.1 	"></polygon>
        <polygon fill="none" points="125.8,-103.8 125.8,-119 110.6,-103.8 	"></polygon>
        <polygon fill="none" points="215.3,98.2 203.1,86.1 203.1,110.4 	"></polygon>
        <polygon fill="none" points="203.1,73.3 215.3,61.2 203.1,49 	"></polygon>
        <polygon fill="none" points="231.1,45.4 217.4,31.7 203.7,45.4 217.4,59 	"></polygon>
        <polygon fill="none" points="219.5,61.2 231.7,73.3 231.7,49 	"></polygon>
        <polygon fill="none" points="231.7,110.4 231.7,86.1 219.5,98.2 	"></polygon>
        <polygon fill="none" points="231.1,114 217.4,100.3 203.7,114 217.4,127.7 	"></polygon>
        <polygon fill="none" points="215.3,29.6 203.1,17.5 203.1,41.7 	"></polygon>
        <polygon fill="none" points="231.7,41.7 231.7,17.5 219.5,29.6 	"></polygon>
        <polygon fill="none" points="203.1,142 215.3,129.8 203.1,117.6 	"></polygon>
        <polygon fill="none" points="219.5,-7.8 231.7,4.3 231.7,-19.9 	"></polygon>
        <polygon fill="none" points="231.7,-51.5 219.5,-39.4 231.7,-27.2 	"></polygon>
        <polygon fill="none" points="229.2,84.4 205.6,84.4 217.4,96.1 	"></polygon>
        <polygon fill="none" points="217.4,-9.9 231.1,-23.6 217.4,-37.2 203.8,-23.6 	"></polygon>
        <polygon fill="none" points="203.1,4.3 215.3,-7.8 203.1,-20 	"></polygon>
        <polygon fill="none" points="215.3,-39.4 203.1,-51.5 203.1,-27.2 	"></polygon>
        <polygon fill="none" points="219.5,129.8 231.7,142 231.7,117.6 	"></polygon>
        <polygon fill="none" points="203.1,179.4 215.3,167.2 203.1,155.1 	"></polygon>
        <polygon fill="none" points="217.4,169.4 205.2,181.5 229.6,181.5 	"></polygon>
        <polygon fill="none" points="229.6,153 205.2,153 217.4,165.1 	"></polygon>
        <polygon fill="none" points="231.7,155.1 219.5,167.2 231.7,179.4 	"></polygon>
        <polygon fill="none" points="217.4,131.9 205.2,144.1 229.6,144.1 	"></polygon>
        <rect x="334.4" y="-95" fill="#FFC200" width="38.9" height="6.1"></rect>
        <rect x="334.4" y="-127.8" fill="#FFC200" width="38.9" height="24"></rect>
        <polygon fill="#DADADA"
                 points="189.2,199.2 183.7,199.2 183.7,215.2 252.6,215.2 252.6,199.2 246.2,199.2 	"></polygon>
        <path fill="#FFC200"
              d="M190.7,193.3v2.9h53.9v-2.9c0-4.9-3.9-8.8-8.8-8.8h-36.3C194.6,184.5,190.7,188.4,190.7,193.3z"></path>
        <path fill="#0E59A4" d="M234.7,183h-1.5l-1.1,1.1l-2.6-2.6h-24.3l-2.6,2.6l-1.1-1.1h-1.5v-1.5h-0.6c-6.5,0-11.8,5.3-11.8,11.8v2.9
 h-5.5c-0.8,0-1.5,0.7-1.5,1.5v19c0,0.8,0.7,1.5,1.5,1.5h71.9c0.8,0,1.5-0.7,1.5-1.5v-19c0-0.8-0.7-1.5-1.5-1.5h-6.5v-2.9
 c0-6.5-5.3-11.8-11.8-11.8h-1.1V183z M235.8,184.5c4.9,0,8.8,3.9,8.8,8.8v2.9h-53.9v-2.9c0-4.9,3.9-8.8,8.8-8.8H235.8z
 M252.6,215.2h-68.9v-16h5.5h57h6.4V215.2z"></path>
        <path fill="#0E59A4" d="M229.6,153l-12.2,12.1L205.2,153l-2.6-2.6l-2.1,2.1l0.4,0.4h-0.9v28.5v1.5h1.5l1.1,1.1l2.6-2.6l12.2-12.1
 l12.2,12.1l2.6,2.6l1.1-1.1h1.5v-1.5V153H229.6z M203.1,179.4v-24.3l12.2,12.1L203.1,179.4z M231.7,155.1v24.3l-12.2-12.1
 L231.7,155.1z"></path>
        <rect x="200.5" y="147.1" fill="#FFC200" width="33.8" height="2.9"></rect>
        <path fill="#0E59A4" d="M234.3,144.5l-2.1,2.1l-2.6-2.6h-24.4l-2.6,2.6l-2.1-2.1l0.4-0.4h-0.9H199c-0.8,0-1.5,0.7-1.5,1.5v5.9
 c0,0.8,0.7,1.5,1.5,1.5h1.1h0.9l-0.4-0.4l2.1-2.1l2.6,2.6h24.3h5.1h1.1c0.8,0,1.5-0.7,1.5-1.5v-5.9c0-0.8-0.7-1.5-1.5-1.5h-1.1
 h-0.9L234.3,144.5z M200.5,150v-2.9h33.8v2.9H200.5z"></path>
        <rect x="200.5" y="78.5" fill="#FFC200" width="33.8" height="2.9"></rect>
        <path fill="#0E59A4" d="M234.3,75.9l-2.1,2.1l-2.6-2.6h-24.4l-2.6,2.6l-2.1-2.1l0.4-0.4h-0.9H199c-0.8,0-1.5,0.7-1.5,1.5v5.9
 c0,0.8,0.7,1.5,1.5,1.5h1.1h1.3l-0.8-0.8l2.1-2.1l3,3h23.5l3-3l2.1,2.1l-0.8,0.8h1.3h1.1c0.8,0,1.5-0.7,1.5-1.5V77
 c0-0.8-0.7-1.5-1.5-1.5h-1.1h-0.9L234.3,75.9z M200.5,81.4v-2.9h33.8v2.9H200.5z"></path>
        <path fill="#0E59A4" d="M234.3,83.5l-2.1-2.1l-3,3l-11.8,11.7l-11.8-11.7l-3-3l-2.1,2.1l0.8,0.8h-1.3v59.7h0.9l-0.4,0.4l2.1,2.1
 l2.6-2.6l12.2-12.2l12.2,12.2l2.6,2.6l2.1-2.1l-0.4-0.4h0.9V84.4h-1.3L234.3,83.5z M215.3,98.2l-12.2,12.1V86.1L215.3,98.2z
 M217.4,100.3l13.7,13.6l-13.7,13.7L203.7,114L217.4,100.3z M203.1,142v-24.4l12.2,12.2L203.1,142z M219.5,129.8l12.2-12.2V142
 L219.5,129.8z M231.7,110.4l-12.2-12.1l12.2-12.1V110.4z"></path>
        <rect x="200.5" y="9.4" fill="#FFC200" width="33.8" height="2.9"></rect>
        <path fill="#0E59A4" d="M234.3,6.9L232.1,9l-2.6-2.6h-24.4L202.6,9l-2.1-2.1l0.4-0.4h-0.9H199c-0.8,0-1.5,0.7-1.5,1.5v5.9
 c0,0.8,0.7,1.5,1.5,1.5h1.1h0.9l-0.4-0.4l2.1-2.1l2.6,2.6h24.3l2.6-2.6l2.1,2.1l-0.4,0.4h0.9h1.1c0.8,0,1.5-0.7,1.5-1.5V7.9
 c0-0.8-0.7-1.5-1.5-1.5h-1.1h-0.9L234.3,6.9z M200.5,12.4V9.4h33.8v2.9H200.5z"></path>
        <path fill="#FFC200" d="M-14.2-99.4c0,0.8,0.6,1.4,1.4,1.4H1.8h14.1h185.1h32.4h147c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
 H156H-12.8C-13.5-100.8-14.2-100.1-14.2-99.4z"></path>
        <path fill="#0E59A4" d="M201.1-98H15.9H1.8h-14.6c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h-0.3v-0.8l-1.7-1.7
 c-1.4,0.7-2.4,2.2-2.4,3.9c0,2.4,2,4.4,4.4,4.4H0.3v8.2c0,0.8,0.7,1.5,1.5,1.5h14.1c0.8,0,1.5-0.7,1.5-1.5V-95h182.1v-1.5
 C199.6-97.3,200.3-98,201.1-98z M14.4-88.3H3.3V-95h11.1V-88.3z"></path>
        <path fill="#0E59A4" d="M380.5-103.8h-4.2v-25.5c0-0.8-0.7-1.5-1.5-1.5h-41.9c-0.8,0-1.5,0.7-1.5,1.5v4.1l2.2,1.3
 c0.6,0.3,0.9,1,0.7,1.7c-0.2,0.7-0.8,1.1-1.5,1.1h-1.5v15.1l2.6,2.6l-2.1,2.1l-2.5-2.5h-15.2v1.4h-3v-1.4h-15.1l-2.4,2.5l-1.1-1.1
 l-1.1,1.1l-2.5-2.5h-15.2v1.4h-3v-1.4h-15.1l-2.4,2.5l-2.1-2.1l0.3-0.3h-17.5v1.7h-1.5l-1.1,1.1l-2.8-2.8h-23.9l-2.8,2.8l-1.1-1.1
 h-1.5v-1.7h-11l0.3,0.3l-2.1,2.1l-2.5-2.5h-15.3v1.4h-3v-1.4H156v3h224.6c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4h-147
 c0.8,0,1.5,0.7,1.5,1.5v1.5h96.4v7.6c0,0.8,0.7,1.5,1.5,1.5h41.9c0.8,0,1.5-0.7,1.5-1.5V-95h4.2c2.4,0,4.4-2,4.4-4.4
 C384.9-101.8,383-103.8,380.5-103.8z M334.4-127.8h38.9v24h-38.9V-127.8z M334.4-88.9V-95h38.9v6.1H334.4z"></path>
        <path fill="#0E59A4" d="M234.3,14.9l-2.1-2.1l-2.6,2.6l-12.2,12.1l-12.2-12.1l-2.6-2.6l-2.1,2.1l0.4,0.4h-0.9v60.1h0.9l-0.4,0.4
 l2.1,2.1l2.6-2.6l12.2-12.2l12.2,12.2l2.6,2.6l2.1-2.1l-0.4-0.4h0.9V15.4h-0.9L234.3,14.9z M215.3,29.6l-12.2,12.1V17.5L215.3,29.6
 z M217.4,31.7l13.7,13.6L217.4,59l-13.7-13.7L217.4,31.7z M203.1,73.3V49l12.2,12.2L203.1,73.3z M219.5,61.2L231.7,49v24.4
 L219.5,61.2z M231.7,41.7l-12.2-12.1l12.2-12.1V41.7z"></path>
        <path fill="#0E59A4"
              d="M233.5-98h-32.4c-0.8,0-1.5,0.7-1.5,1.5v1.5v8.8h3V-95H232v8.8h3V-95v-1.5C235-97.3,234.3-98,233.5-98z"></path>
        <path fill="#0E59A4" d="M229.1-53.2l-11.7,11.7l-11.7-11.7h-5.6V6.4h0.9l-0.4,0.4l2.1,2.1l2.6-2.6l12.2-12.2l12.2,12.2l2.6,2.6
 l2.1-2.1l-0.4-0.4h0.9v-59.6H229.1z M231.7-51.5v24.4l-12.2-12.2L231.7-51.5z M231.1-23.6L217.4-9.9l-13.7-13.7l13.6-13.6
 L231.1-23.6z M215.3-39.4l-12.2,12.2v-24.4L215.3-39.4z M203.1,4.3V-20l12.2,12.2L203.1,4.3z M219.5-7.8l12.2-12.1V4.3L219.5-7.8z"></path>
        <rect x="189.5" y="-63.2" fill="#FFC200" width="49.2" height="7"></rect>
        <path fill="#1CC6F5" d="M201.1-83.2h-1.6c-5.5,0-10,4.5-10,10v7h49.2v-17h-5.2H201.1z"></path>
        <path fill="#0E59A4" d="M202.6-86.2h-3h-0.1c-7.2,0-13,5.8-13,13v8.5v10c0,0.8,0.7,1.5,1.5,1.5h12.1h5.6h23.4h5.6h5.5
 c0.8,0,1.5-0.7,1.5-1.5v-10v-20c0-0.8-0.7-1.5-1.5-1.5H235h-3H202.6z M189.5-56.2v-7h49.2v7H189.5z M238.7-66.2h-49.2v-7
 c0-5.5,4.5-10,10-10h1.6h32.4h5.2V-66.2z"></path>
        <rect x="3.3" y="-95" fill="#FFC200" width="11.1" height="6.7"></rect>
        <path fill="#0E59A4" d="M-13.1-101.6v0.8h0.3H156v-3h-5.1l15.2-15.2v15.2v1.4h3v-1.4v-15.3l15.3,15.3l2.5,2.5l2.1-2.1l-0.3-0.3
 l-19.9-19.9c-0.3-0.3-0.7-0.4-1.1-0.4h-36.8l68.7-69v89.3v1.7h1.5l1.1,1.1l2.8-2.8l11.9-11.9l11.9,11.9l2.8,2.8l1.1-1.1h1.5v-1.7
 v-61.4c0-0.6-0.3-1.1-0.8-1.3l-23.5-23.6l117.4,66h-54.8c-0.4,0-0.8,0.2-1.1,0.4l-19.8,19.9l-0.3,0.3l2.1,2.1l2.4-2.5l15.1-15.2
 v15.2v1.4h3v-1.4V-119l15.2,15.2l2.5,2.5l1.1-1.1l1.1,1.1l2.4-2.5l15.1-15.2v15.2v1.4h3v-1.4V-119l15.2,15.2l2.5,2.5l2.1-2.1
 l-2.6-2.6l-15.1-15.1h15.1h1.5c0.7,0,1.3-0.5,1.5-1.1c0.2-0.7-0.1-1.4-0.7-1.7l-2.2-1.3L201.8-198c0,0,0,0,0,0c0,0-0.1,0-0.1,0
 c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0
 c0,0,0,0,0,0l-154.1,74h-40c-0.4,0-0.8,0.2-1.1,0.4l-20.2,20.2l0.2,0.2L-13.1-101.6z M294.1-121.1h15l-15,15.1V-121.1z M291.1-106
 L276-121.1h15.1V-106z M125.8-103.8h-15.2l15.2-15.2V-103.8z M128.8-118.9l15.1,15.2h-15.1V-118.9z M85.5-103.8H70.3L85.5-119
 V-103.8z M88.5-118.9l15.1,15.2H88.5V-118.9z M45.2-103.8H30L45.2-119V-103.8z M48.2-118.9l15.1,15.2H48.2V-118.9z M7.8-103.8
 v-15.2l15.1,15.2H7.8z M145.9-106l-15-15.1h15V-106z M148.9-121.1H164L148.9-106V-121.1z M108.6-106v-15.1h15.1L108.6-106z
 M105.6-106l-15-15.1h15V-106z M68.3-106v-15.1h15.1L68.3-106z M65.3-106l-15-15.1h15V-106z M28-106v-15.1H43L28-106z M25-106
 l-15-15.1h15V-106z M4.8-103.8h-15.2L4.8-119V-103.8z M202.6-161.6l12.2,12.2l-12.2,12.2V-161.6z M216.9-147.2l13.6,13.7L216.8-120
 l-13.6-13.6L216.9-147.2z M219-149.4l12.1-12.1v24.3L219-149.4z M216.9-151.5l-13.6-13.7l13.6-13.6l13.6,13.7L216.9-151.5z
 M202.6-168.7v-24.4l12.2,12.2L202.6-168.7z M202.6-105.7V-130l12.1,12.1L202.6-105.7z M231.1-130v24.3L219-117.8L231.1-130z
 M193.9-191.6l-67.2,67.5H53.2L193.9-191.6z"></path>
      </g>
      <g id="cable">
        <g>
          <rect x="7.3" y="-87" fill="#0E59A4" width="3" height="32.8"></rect>
        </g>
      </g>
    </g>
    <g id="mini-crane">
      <g id="mini-crane-box">
        <polygon fill="none" points="-171.8,169.5 -185.8,183 -157.9,183 	"></polygon>
        <path fill="#1CC6F5" d="M-153.5,186h-36.7c-1,0-1.8,0.8-1.8,1.8v13.4c0,1,0.8,1.8,1.8,1.8h36.7c1,0,1.8-0.8,1.8-1.8v-13.4
 C-151.7,186.8-152.5,186-153.5,186z"></path>
        <path fill="#0E59A4" d="M-153.5,183L-153.5,183l-17.3-16.7c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.2-0.2
 c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.1
 c-0.1,0.1-0.2,0.1-0.3,0.2l-17.3,16.7h-0.1c-2.6,0-4.8,2.2-4.8,4.8v13.4c0,2.6,2.2,4.8,4.8,4.8h36.7c2.6,0,4.8-2.2,4.8-4.8v-13.4
 C-148.7,185.1-150.8,183-153.5,183z M-151.7,201.2c0,1-0.8,1.8-1.8,1.8h-36.7c-1,0-1.8-0.8-1.8-1.8v-13.4c0-1,0.8-1.8,1.8-1.8h36.7
 c1,0,1.8,0.8,1.8,1.8V201.2z M-171.8,169.5l13.9,13.5h-27.9L-171.8,169.5z"></path>
      </g>
      <g id="mini-crane-hook">
        <path fill="#0E59A4" d="M-169.5,154.5v-2.6h2.7c0.7,0,1.4-0.5,1.5-1.3l2.9-19.3c0.1-0.4-0.1-0.9-0.3-1.2s-0.7-0.5-1.1-0.5h-4.1
 V97.1h-3v32.5h-1.7v-22.2h-3v22.2h-4.1c-0.4,0-0.9,0.2-1.1,0.5c-0.3,0.3-0.4,0.8-0.3,1.2l3,19.3c0.1,0.7,0.7,1.3,1.5,1.3h2.7v4.6
 c0,1.4,0.9,2.3,2.3,2.3c2.5,0,4.6,2.1,4.6,4.6c0,2.5-2.1,4.6-4.6,4.6c-2.5,0-4.6-2.1-4.6-4.6c0-1.4-0.9-2.3-2.3-2.3
 s-2.3,0.9-2.3,2.3c0,5.1,4.2,9.3,9.3,9.3s9.3-4.2,9.3-9.3C-162.6,159.1-165.6,155.5-169.5,154.5z M-174.2,132.6h4.7h3.9l-2.5,16.3
 h-7.4l-2.5-16.3H-174.2z"></path>
        <polygon fill="#DADADA"
                 points="-168.1,148.9 -165.6,132.6 -169.5,132.6 -174.2,132.6 -178,132.6 -175.5,148.9 	"></polygon>
      </g>
      <g>
        <path fill="#FFFFFF" d="M-201.9,202.8c0-6.7-5.7-12.4-12.4-12.4h-54.9c-6.7,0-12.4,5.7-12.4,12.4s5.7,12.4,12.4,12.4h54.9
 C-207.6,215.2-201.9,209.5-201.9,202.8z M-215,212.3h-53.5c-5.2,0-9.5-4.3-9.5-9.5s4.3-9.5,9.5-9.5h53.5c5.2,0,9.5,4.3,9.5,9.5
 S-209.8,212.3-215,212.3z"></path>
        <path fill="#0E59A4" d="M-214.3,187.4h-54.9c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4h54.9c8.5,0,15.4-6.9,15.4-15.4
 S-205.8,187.4-214.3,187.4z M-269.2,215.2c-6.7,0-12.4-5.7-12.4-12.4s5.7-12.4,12.4-12.4h54.9c6.7,0,12.4,5.7,12.4,12.4
 s-5.7,12.4-12.4,12.4H-269.2z"></path>
        <path fill="#ECECEB" d="M-224.4,202.8c0-2.5,1-4.8,2.6-6.5h-13.2c1.6,1.7,2.6,4,2.6,6.5c0,2.5-1,4.8-2.6,6.5h13.2
 C-223.4,207.6-224.4,205.3-224.4,202.8z"></path>
        <path fill="#ECECEB" d="M-251.1,202.8c0-2.5,1-4.8,2.6-6.5h-13.4c1.6,1.7,2.6,4,2.6,6.5c0,2.5-1,4.8-2.6,6.5h13.4
 C-250.1,207.6-251.1,205.3-251.1,202.8z"></path>
        <path fill="#0E59A4" d="M-215,193.3h-53.5c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5h53.5c5.2,0,9.5-4.3,9.5-9.5
 S-209.8,193.3-215,193.3z M-268.6,196.4c3.5,0,6.4,2.9,6.4,6.4c0,3.5-2.9,6.4-6.4,6.4s-6.4-2.9-6.4-6.4
 C-275,199.3-272.1,196.4-268.6,196.4z M-259.2,202.8c0-2.5-1-4.8-2.6-6.5h13.4c-1.6,1.7-2.6,4-2.6,6.5c0,2.5,1,4.8,2.6,6.5h-13.4
 C-260.2,207.6-259.2,205.3-259.2,202.8z M-248.1,202.8c0-3.5,2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4c0,3.5-2.9,6.4-6.4,6.4
 S-248.1,206.3-248.1,202.8z M-232.3,202.8c0-2.5-1-4.8-2.6-6.5h13.2c-1.6,1.7-2.6,4-2.6,6.5c0,2.5,1,4.8,2.6,6.5h-13.2
 C-233.3,207.6-232.3,205.3-232.3,202.8z M-221.4,202.8c0-3.5,2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4c0,3.5-2.9,6.4-6.4,6.4
 S-221.4,206.3-221.4,202.8z"></path>
        <path fill="#FFC200" d="M-268.6,209.2c3.5,0,6.4-2.9,6.4-6.4c0-3.5-2.9-6.4-6.4-6.4s-6.4,2.9-6.4,6.4
 C-275,206.3-272.1,209.2-268.6,209.2z"></path>
        <path fill="#FFC200"
              d="M-235.3,202.8c0-3.5-2.9-6.4-6.4-6.4s-6.4,2.9-6.4,6.4c0,3.5,2.9,6.4,6.4,6.4S-235.3,206.3-235.3,202.8z"></path>
        <path fill="#FFC200"
              d="M-208.6,202.8c0-3.5-2.9-6.4-6.4-6.4s-6.4,2.9-6.4,6.4c0,3.5,2.9,6.4,6.4,6.4S-208.6,206.3-208.6,202.8z"></path>
      </g>
      <g>
        <polygon fill="none" points="-214.5,143.6 -212,160 -209.8,160 -203.9,150.7 	"></polygon>
        <polygon fill="none" points="-214.1,140.3 -204.1,146.9 -206.7,131 	"></polygon>
        <polygon fill="none" points="-203.1,126.6 -192.8,133.3 -195.3,116.8 	"></polygon>
        <polygon fill="none" points="-203.9,129.7 -201.2,146.5 -194.4,135.9 	"></polygon>
        <polygon fill="none" points="-178.7,95.9 -171.8,100.4 -171.4,99.8 -172.5,88.3 	"></polygon>
        <polygon fill="none" points="-190.9,111.3 -182.3,116.9 -182,116.5 -183.8,102.4 	"></polygon>
        <rect x="-263.6" y="137.7" fill="none" width="15.9" height="13.2"></rect>
        <polygon fill="none" points="-168.8,95.7 -164.8,89.4 -169.7,86.1 	"></polygon>
        <polygon fill="none" points="-217.4,144.5 -229.8,160 -215.1,160 	"></polygon>
        <polygon fill="none" points="-192.8,113.6 -192.8,113.6 -190.4,129.5 -183.9,119.5 	"></polygon>
        <polygon fill="none" points="-180.5,98.3 -181.2,99.1 -179.5,112.5 -173.4,102.9 	"></polygon>
        <path fill="#DADADA" d="M-241.2,162.3c-0.3-0.4-0.3-0.9-0.2-1.4l3.9-9.9v-24.1H-275v48.4h14.7c0.8,0,1.5,0.7,1.5,1.5v4.8h37.7v-4.8
 c0-0.8,0.7-1.5,1.5-1.5h9.3V163h-29.6C-240.4,163-240.9,162.7-241.2,162.3z M-244.7,152.4c0,0.8-0.7,1.5-1.5,1.5h-18.9
 c-0.8,0-1.5-0.7-1.5-1.5v-16.2c0-0.8,0.7-1.5,1.5-1.5h18.9c0.8,0,1.5,0.7,1.5,1.5V152.4z"></path>
        <path fill="#0E59A4" d="M-246.2,134.7h-18.9c-0.8,0-1.5,0.7-1.5,1.5v16.2c0,0.8,0.7,1.5,1.5,1.5h18.9c0.8,0,1.5-0.7,1.5-1.5v-16.2
 C-244.7,135.4-245.4,134.7-246.2,134.7z M-247.7,150.9h-15.9v-13.2h15.9V150.9z"></path>
        <path fill="#0E59A4" d="M-161.9,87.7l-8.1-5.3c-0.6-0.4-1.5-0.3-2,0.3l-46,57.6l-0.2,0l0,0.2l-15.6,19.5h-4.1l3.2-8.2
 c0.1-0.2,0.1-0.4,0.1-0.5v-25.9c0-0.8-0.7-1.5-1.5-1.5h-40.6c-0.8,0-1.5,0.7-1.5,1.5v51.4c0,0.8,0.7,1.5,1.5,1.5h14.7v3.3h-6.6
 c-0.8,0-1.5,0.7-1.5,1.5v5.7c0,0.8,0.7,1.5,1.5,1.5h53.4c0.8,0,1.5-0.7,1.5-1.5v-5.7c0-0.8-0.7-1.5-1.5-1.5h-3.1v-3.3h9.3
 c0.8,0,1.5-0.7,1.5-1.5v-15.1l45.9-71.9C-161,89.1-161.2,88.2-161.9,87.7z M-171.8,100.4l-6.9-4.5l6.1-7.7l1.2,11.5L-171.8,100.4z
 M-182.3,116.9l-8.6-5.7l7.1-8.9l1.8,14.1L-182.3,116.9z M-210.3,175.3h-9.3c-0.8,0-1.5,0.7-1.5,1.5v4.8h-37.7v-4.8
 c0-0.8-0.7-1.5-1.5-1.5H-275v-48.4h37.6V151l-3.9,9.9c-0.2,0.5-0.1,1,0.2,1.4c0.3,0.4,0.7,0.7,1.2,0.7h29.6V175.3z M-216.5,187.3
 h-50.4v-2.7h50.4V187.3z M-209.8,160h-2.3l-2.5-16.4l10.7,7.1L-209.8,160z M-214.1,140.3l7.4-9.3l2.5,15.9L-214.1,140.3z
 M-203.9,129.7l9.5,6.2l-6.8,10.6L-203.9,129.7z M-203.1,126.6l7.8-9.7l2.5,16.5L-203.1,126.6z M-192.8,113.6L-192.8,113.6l8.9,5.8
 l-6.4,10.1L-192.8,113.6z M-181.2,99.1l0.7-0.8l7.1,4.7l-6.1,9.6L-181.2,99.1z M-217.4,144.5l2.4,15.5h-14.7L-217.4,144.5z
 M-168.8,95.7l-1-9.6l5,3.3L-168.8,95.7z"></path>
        <rect x="-266.9" y="184.6" fill="#FFC200" width="50.4" height="2.7"></rect>
      </g>
    </g>
  </svg>
</div>
