import { Component } from '@angular/core';

@Component({
  selector: 'helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.scss']
})
export class HelpdeskComponent {

  constructor() { }

}
