import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AppValidators {

  constructor() {
  }

  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (formGroup: FormGroup): ValidationErrors => {
      const password = formGroup.controls[passwordKey];
      const confirmPassword = formGroup.controls[confirmPasswordKey];

      if (!password || !confirmPassword) {
        return null;
      }

      if ((!!password.value && !!confirmPassword.value) && password.value !== confirmPassword.value) {
        confirmPassword.setErrors({mismatchPassword: true});
      }
      else {
        const errors = confirmPassword.getError('mismatchPassword');
        if (errors) {
          confirmPassword.setErrors(null);
        }
      }
    };
  }
}
