import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

declare var jQuery;

@Component({
  selector: 'app-reset-pin-request-success',
  templateUrl: './reset-pin-request-success.component.html',
  styleUrls: ['./reset-pin-request-success.component.scss']
})
export class ResetPinRequestSuccessComponent implements OnInit, OnDestroy {

  token: any;

  constructor(private router: Router) {
    const extras = router.getCurrentNavigation().extras.state || {};
    this.token = extras.token;

    if (!this.token) {
      router.navigate(['/pin-reset-request']);
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scroll(0, 0);
    });
  }

  ngOnInit(): void {
    jQuery('.portal-content').addClass('wide');

    window.setTimeout(() => {
      jQuery('#successTxt').focus();
    }, 0);
  }

  ngOnDestroy() {
    jQuery('.portal-content').removeClass('wide');
  }

}
