import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortalService {

  private requestSoftTokenAPI = '/mytoken/api/token';
  private resetPINAPI = '/mytoken/api/token/pin/reset';
  private getConfigDetailsAPI = '/mytoken/api/configDetails';
  private requestOTPAPI = '/mytoken/api/otp/request';
  private validateOTPAPI = '/mytoken/api/otp/validate';

  private DOMAINS: [any];
  private mppUrl: string;
  private encKey: string;

  constructor(private client: HttpClient) { }

  loadConfigDetails(): Promise<any> {
    return this.client.get(this.getConfigDetailsAPI, {headers: this.headers()})
      .toPromise()
      .then((result: any) => {
        this.DOMAINS = result.result.domains || [];
        this.mppUrl = result.result.mppUrl || '';
        this.encKey = result.result.encKey || '';
      });
  }

  getMppUrl(): string {
    return this.mppUrl;
  }

  getDomains(): [any] {
    return this.DOMAINS;
  }

  getEncKey(): string {
    return this.encKey;
  }

  requestSoftToken(request: any): Observable<any> {
    return this.client.post(this.requestSoftTokenAPI, request, {headers: this.headers()});
  }

  resetPIN(request: any): Observable<any> {
    return this.client.post(this.resetPINAPI, request, {headers: this.headers()});
  }

  requestOTP(request: any): Observable<any> {
    return this.client.post(this.requestOTPAPI, request, {headers: this.headers()});
  }

  validateOTP(request: any): Observable<any> {
    return this.client.post(this.validateOTPAPI, request, {headers: this.headers()});
  }

  private headers(): HttpHeaders {
    return new HttpHeaders()
      .set('content-type', 'application/json');
  }
}
