import { Injectable } from '@angular/core';

declare var $;

@Injectable({
  providedIn: 'root'
})
export class TooltipService {

  constructor() { }

  init() {
    $('[data-toggle="tooltip"]').tooltip({
      /* there is a bug preventing fallbackPlacement from working as expected.
       * instead of swapping positions, the tooltip will stay fixed to the right.
       * this behavior is better than the default {flip} since the tooltip may obstruct the input field
       * when flipped (to the left) */
      placement: 'right',
      fallbackPlacement: ['top'],
      trigger: 'hover focus'
    });
  }

  destroy() {
    $('[data-toggle="tooltip"]').tooltip('dispose');
  }
}
