import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NotFoundComponent} from './not-found/not-found.component';
import {ReactiveFormsModule} from '@angular/forms';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {HelpdeskComponent} from './helpdesk/helpdesk.component';
import {SoftTokenRequestComponent} from './soft-token-request/soft-token-request.component';
import {SoftTokenRequestSuccessComponent} from './soft-token-request-success/soft-token-request-success.component';
import {DigitOnlyDirective} from './digit-only.directive';
import {ResetPinRequestComponent} from './reset-pin-request/reset-pin-request.component';
import {ResetPinRequestSuccessComponent} from './reset-pin-request-success/reset-pin-request-success.component';
import {HttpClientModule} from '@angular/common/http';
import {PortalService} from './portal.service';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';

export function appInit(portalService: PortalService) {
  return () => portalService.loadConfigDetails();
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LandingPageComponent,
    HelpdeskComponent,
    SoftTokenRequestComponent,
    SoftTokenRequestSuccessComponent,
    DigitOnlyDirective,
    ResetPinRequestComponent,
    ResetPinRequestSuccessComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxIntlTelInputModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [PortalService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
