<div id="helpdesk">
  <div id="helpdesk-content">
    <h1>HelpDesk Support</h1>
    <p class="semibold">The HelpDesk can assist you with</p>
    <ul>
      <li>Password Reset</li>
      <li>Account Unlock</li>
      <li>Provide a temporary access code</li>
      <li>Security Question Validation</li>
    </ul>
    <div id="helpdeskNumbers" class="mt-5">
      <p class="helpdeskNumbers-header">Contact HelpDesk</p>
      <p>
        The phone numbers listed can only be called from the country indicated. Hours of Operation 24x7.
        <br/>
        Charges may vary based on options selected.
      </p>

      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="col">Country</th>
            <th scope="col">Phone Number</th>
          </tr>
          <tr>
            <td><span class="semibold">North America</span></td>
            <td>1‐866‐576‐1818</td>
          </tr>
          <tr>
            <td><span class="semibold">United Kingdom</span></td>
            <td>0800‐917‐2388</td>
          </tr>
          <tr>
            <td><span class="semibold">Ireland</span></td>
            <td>01-612-3999</td>
          </tr>
          <tr>
            <td><span class="semibold">Australia</span></td>
            <td>1‐800‐798‐152</td>
          </tr>
          <tr>
            <td><span class="semibold">India</span></td>
            <td>0008‐0044‐05142</td>
          </tr>
          <tr>
            <td><span class="semibold">Japan</span></td>
            <td>012‐0984‐188</td>
          </tr>
          <tr>
            <td><span class="semibold">Hong Kong</span></td>
            <td>3‐017‐4328</td>
          </tr>
          <tr>
            <td><span class="semibold">Singapore</span></td>
            <td>1‐800‐723‐1449</td>
          </tr>
          <tr>
            <td><span class="semibold">Philippines</span></td>
            <td>1‐800‐8739‐5128 – Global<br/>1‐800‐1441‐1109 – PLDT</td>
          </tr>
          <tr>
            <td><span class="semibold">All remaining locations</span><br/>
              <span class="disclaimer">(Calls to this number from outside the US will incur international long distance charges)</span>
            </td>
            <td>+1 713-751-0379</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

