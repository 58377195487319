<div id="landing-page">
  <div class="landing-page-content">
    <div class="page-heading" tabindex="-1">
      <h1>Token Assistance</h1>
      <p class="title-description tagline">Token setup to remotely access corporate network</p>
    </div>
    <div class="getting-started">
      <h2>Before Getting Started</h2>
      <p>
        You will need your manager to provide you a Verbal Access Code, they can find this code through Generate Verbal Code on myToken portal
      </p>
    </div>
    <div>
      <fieldset class="token-assistance">
        <legend>
          <p>What would you like to do today?</p>
        </legend>
        <ul role="list">
          <li id="tokenAssistOption1" role="listitem">
            <a [routerLink]="['soft-token-request']" class="box" aria-describedby="cp-description" aria-label="Request Soft Token">
              <div class="img-container"><img class="img-mobile" src="assets/req-soft_token_icon.svg" alt="Request Soft Token"></div>
              <div class="flow-content">
                <p class="flow-name">Request Soft Token</p>
                <p class="flow-description" id="cp-description">You need a temporary token to access the network.</p>
                <p class="flow-helper semibold small">CURRENT EMPLOYEES/CONTRACTORS</p>
              </div>
            </a>
          </li>
          <li id="tokenAssistOption2" role="listitem">
            <a [routerLink]="['pin-reset-request']" class="box" aria-describedby="ap-description" aria-label="Reset Pin">
              <div class="img-container"><img class="img-mobile" src="assets/reset_pin_icon.svg" alt="Reset Pin"></div>
              <div class="flow-content">
                <p class="flow-name">Reset Pin</p>
                <p class="flow-description" id="ap-description">You have a token however you forgot your pin.</p>
                <p class="flow-helper semibold small">CURRENT EMPLOYEES/CONTRACTORS</p>
              </div>
            </a>
          </li>
        </ul>
      </fieldset>
    </div>
  </div>
</div>
