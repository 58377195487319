import {Injectable} from '@angular/core';
import {JSEncrypt} from "jsencrypt";

@Injectable({
  providedIn: 'root'
})
export class EncryptService {
  doEncrypt(message,pubkey) {

    let jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(pubkey);
    return jsEncrypt.encrypt(message);

  };
}
