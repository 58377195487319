<div id="pin_reset_request" class="portal-content-screen">
  <h1>Reset PIN</h1>

  <form [formGroup]="requestForm" class="pt-3" [style]="{display: entryState === 'pinreset' ? 'block' : 'none'}">

    <div formGroupName="user">
      <div class="form-group">
        <label for="sid" class="tuxedo_label required">Standard ID (SID)</label>
        <input type="text" class="form-control" id="sid" formControlName="sid" required aria-required="true" maxlength="100">
        <div *ngIf="sid.touched && sid.errors?.required" class="validation-state error">
          Standard ID (SID) is required
        </div>
      </div>
      <div class="form-group">
        <label for="password" class="tuxedo_label required">Password</label>
        <input type="password" class="form-control" id="password" formControlName="password" required
          aria-required="true">
        <small class="form-text">
          <a href="https://password.jpmchase.com" target="Managed-Password-Portal">Forgot your password?</a>
        </small>
        <div *ngIf="password.touched && password.errors?.required" class="validation-state error">
          Password is required
        </div>
      </div>
      <div class="form-group">
        <label for="domain" class="tuxedo_label required">Domain</label>
        <div class="select_wrapper">
          <select class="form-control" id="domain" formControlName="domain" required aria-required="true">
            <option value="">Select Domain</option>
            <option *ngFor="let domain of DOMAINS" value="{{domain}}">{{domain}}</option>
          </select>
          <i class="caret_container" aria-hidden="true"></i>
        </div>
        <div *ngIf="domain.touched && domain.errors?.required" class="validation-state error">
          Domain is required
        </div>
      </div>

      <div class="form-group" *ngIf="validationBy === 'byManager'">
        <label for="voiceAccessCode" class="tuxedo_label required">Verbal Access Code</label>
        <small class="form-text" id="voiceAccessCodeHelperText">
          Enter the code provided to you by your manager
        </small>
        <input type="password" class="form-control" id="voiceAccessCode" formControlName="voiceAccessCode" required
          aria-required="true"
          aria-describedby="voiceAccessCodeHelperText"
          inputmode="numeric">
        <div *ngIf="voiceAccessCode.touched && voiceAccessCode.errors?.required" class="validation-state error">
          One-time Access Code is required
        </div>
        <small class="form-text">
          <a href="javascript:void(0)" id="managerLink" (click)="initOTP()">Can't contact your manager?</a>
        </small>
      </div>

      <div class="form-group">
        <label for="mobileAccessCode"
          class="tuxedo_label required">{{validationBy === 'byManager' ? 'Mobile Access Code' : 'Access Code'}}</label>
        <small class="form-text" id="mobileAccessCodeHelperText">
          Enter the authentication code sent to your registered mobile device
        </small>
        <input *ngIf="validationBy !== 'byManager'" type="password" class="form-control" id="mobileAccessCode" formControlName="mobileAccessCode" required
          aria-describedby="mobileAccessCodeHelperText"
          inputmode="numeric">
        <input *ngIf="validationBy === 'byManager'" type="password" class="form-control" id="mobileAccessCode" formControlName="mobileAccessCode" required
          aria-describedby="mobileAccessCodeHelperText" data-toggle="tooltip"
          title="A mobile access code will be sent to your registered mobile device via SMS (text) after a verbal access code is generated by your manager"
          inputmode="numeric">
        <div *ngIf="mobileAccessCode.touched && mobileAccessCode.errors?.required" class="validation-state error">
          Authentication Code is required
        </div>
      </div>

      <!--  Sec Q&As  -->
      <div formArrayName="userAnswersInfo">
        <div class="form-group" *ngFor="let ans of getUserAnswersInfo().controls; let i=index" [formGroupName]="i">
          <label for="userAnswersInfo{{i}}" class="tuxedo_label required">{{qas[i].question}}</label>
          <input type="password" class="form-control" id="userAnswersInfo{{i}}" formControlName="answer" required>
          <div *ngIf="getQA(i).touched && getQA(i).errors?.required" class="validation-state error">
            Security question is required
          </div>
        </div>
      </div>
    </div>

    <div [style]="{display: formState === 'pinreset' ? 'block' : 'none'}">
      <div class="form-group">
        <label for="pin" class="tuxedo_label required">New PIN</label>
        <small class="form-text" id="pinLengthHelperText">
          Must be {{MIN_PIN_DIGITS}}-{{MAX_PIN_DIGITS}} digits and must not start with 0
        </small>
        <input type="password" class="form-control" id="pin" formControlName="pin" required
          aria-required="true"
          aria-describedby="pinLengthHelperText" (input)="forceNumbersOnly($event)"
          minlength="{{MIN_PIN_DIGITS}}" maxlength="{{MAX_PIN_DIGITS}}" inputmode="numeric" appDigitOnly>
        <div *ngIf="pin.touched && pin.errors?.required" class="validation-state error">
          New PIN is required
        </div>
        <div *ngIf="pin.touched && pin.errors?.pattern" class="validation-state error">
          New PIN must not start with 0
        </div>
        <div *ngIf="pin.touched && pin.errors?.minlength" class="validation-state error">
          Minimum length is {{MIN_PIN_DIGITS}} digits
        </div>
        <div *ngIf="pin.touched && pin.errors?.maxlength" class="validation-state error">
          Maximum length is {{MAX_PIN_DIGITS}} digits
        </div>
      </div>
      <div class="form-group">
        <label for="confirmPin" class="tuxedo_label required">Confirm New PIN</label>
        <input type="password" class="form-control" id="confirmPin" formControlName="confirmPin" required
          aria-required="true" minlength="{{MIN_PIN_DIGITS}}" maxlength="{{MAX_PIN_DIGITS}}"
          inputmode="numeric" (input)="forceNumbersOnly($event)" appDigitOnly>
        <div *ngIf="confirmPin.touched && confirmPin.errors?.mismatchPassword" class="validation-state error">
          Passwords did not match
        </div>
        <div *ngIf="confirmPin.touched && confirmPin.errors?.required" class="validation-state error">
          Confirm New PIN is required
        </div>
      </div>
      <div style="margin-top: 50px;">
        <button type="button" class="btn btn-primary w-100" (click)="submitRequest($event)">Submit</button>
      </div>
    </div>

    <div [style]="{display: formState === 'validateOTP' ? 'block' : 'none'}">
      <div style="margin-top: 50px;">
        <button type="button" class="btn btn-primary w-100" (click)="validateOtp($event)">Validate OTP</button>
      </div>
    </div>
  </form>


  <form [formGroup]="otpForm" class="pt-3" [style]="{display: entryState === 'otp' ? 'block' : 'none'}">
    <div class="form-group">
      <label for="sid2" class="tuxedo_label required">Standard ID (SID)</label>
      <input type="text" class="form-control" id="sid2" formControlName="sid" required aria-required="true">
      <div *ngIf="sid2.touched && sid2.errors?.required" class="validation-state error">
        Standard ID (SID) is required
      </div>
    </div>
    <div class="form-group">
      <ngx-intl-tel-input [preferredCountries]="preferredCountries"
        [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true"
        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
        [phoneValidation]="true" [separateDialCode]="true"
        [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="phone">
      </ngx-intl-tel-input>
      <div *ngIf="phone.touched && phone.errors?.required" class="validation-state error">
        Phone number is required
      </div>
    </div>

    <fieldset class="form-group">
      <legend class="tuxedo_label required">Access Code Delivery Method</legend>
      <ul role="group" class="form-row align-items-center align-content-center">
        <li role="listitem" class="col-auto" style="margin-bottom: 8px !important; height: 32px !important;">
          <input class="tuxedo_radio_button" value="sms" formControlName="deliveryMethod" id="sms" type="radio">
          <label for="sms">SMS</label>
        </li>
        <li role="listitem" class="col-auto" style="margin-bottom: 8px !important; height: 32px !important;">
          <input class="tuxedo_radio_button" formControlName="deliveryMethod" value="voice" id="voice" type="radio">
          <label for="voice">Voice</label>
        </li>
      </ul>
      <div *ngIf="deliveryMethod.touched && deliveryMethod.errors?.required" class="validation-state error">
        Access code delivery method is required
      </div>
    </fieldset>

    <div class="d-flex flex-row" style="margin-top: 50px;">
      <button type="button" class="btn btn-secondary" style="flex: 1"
        (click)="cancelOTP()">Cancel
      </button>
      <button type="button" class="btn btn-primary" style="flex: 1"
        (click)="requestOTP($event)">Send Code
      </button>
    </div>

  </form>

  <!--  {{requestForm.value | json}}-->


</div>
