import {Component, OnInit} from '@angular/core';
import {PortalService} from "./portal.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'SecurID Portal';

  currentYear = new Date().getFullYear();

  mpp_url: string;

  constructor(private service: PortalService) {}

  ngOnInit(): void {
    this.mpp_url = this.service.getMppUrl();
  }
}
