import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {HelpdeskComponent} from './helpdesk/helpdesk.component';
import {SoftTokenRequestComponent} from './soft-token-request/soft-token-request.component';
import {SoftTokenRequestSuccessComponent} from './soft-token-request-success/soft-token-request-success.component';
import {ResetPinRequestComponent} from './reset-pin-request/reset-pin-request.component';
import {ResetPinRequestSuccessComponent} from './reset-pin-request-success/reset-pin-request-success.component';


const routes: Routes = [
  { path: '', redirectTo: '/assistance', pathMatch: 'full' },
  { path: 'assistance', component: LandingPageComponent },
  { path: 'helpdesk', component: HelpdeskComponent },
  { path: 'assistance/soft-token-request', component: SoftTokenRequestComponent },
  { path: 'assistance/soft-token-request/success', component: SoftTokenRequestSuccessComponent },
  { path: 'assistance/pin-reset-request', component: ResetPinRequestComponent },
  { path: 'assistance/pin-reset-request/success', component: ResetPinRequestSuccessComponent },
  { path: '**', component: SoftTokenRequestSuccessComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
