import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AppValidators} from '../app-validators.service';
import {AlertService} from '../alert.service';
import {PortalService} from '../portal.service';
import {LoadingMaskService} from '../loading-mask.service';
import {EncryptService} from '../encrypt.service';
import {TooltipService} from '../tooltip.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

declare var jQuery;

@Component({
  selector: 'app-reset-pin-request',
  templateUrl: './reset-pin-request.component.html',
  styleUrls: ['./reset-pin-request.component.scss']
})
export class ResetPinRequestComponent implements OnInit {

  validationBy = 'byManager'; // byManager or byOTP
  entryState = 'pinreset'; // pinreset, otp
  formState = 'pinreset'; // pinreset, validateOTP

  requestForm: FormGroup;
  otpForm: FormGroup;

  MIN_PIN_DIGITS = 6;
  MAX_PIN_DIGITS = 8;

  DOMAINS: [any];
  encKey: string;

  qas: { id: string, sourceSystem: string, answer: string, question: string }[] = [];

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  // [{id: '1', sourceSystem: 'ABC', answer: '', question: 'How are you today?'}];

  constructor(private formBuilder: FormBuilder, private router: Router, private passwordService: AppValidators,
              private alertService: AlertService, private service: PortalService,
              private loadingMask: LoadingMaskService, private encryptService: EncryptService,
              private tooltipService: TooltipService) {

    this.requestForm = this.formBuilder.group({
      user: new FormGroup({
        sid: new FormControl('', [
          Validators.required
        ]),
        password: new FormControl('', [
          Validators.required
        ]),
        domain: new FormControl('', [
          Validators.required
        ]),
        voiceAccessCode: new FormControl(''),
        mobileAccessCode: new FormControl('', [
          Validators.required
        ]),
        userAnswersInfo: this.formBuilder.array([])
      }),
      pin: new FormControl('', [
        Validators.required,
        Validators.minLength(this.MIN_PIN_DIGITS),
        Validators.maxLength(this.MAX_PIN_DIGITS),
        Validators.pattern(/^[1-9]\d*/)
      ]),
      confirmPin: new FormControl('', [
        Validators.required
      ])
    }, {
      validators: [passwordService.matchingPasswords('pin', 'confirmPin')]
    });

    this.otpForm = this.formBuilder.group({
      sid: new FormControl('', [Validators.required]),
      phone: new FormControl('',[Validators.required]),
      deliveryMethod: new FormControl('sms', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.DOMAINS = this.service.getDomains();
    this.encKey = this.service.getEncKey();

    window.setTimeout(() => {
      jQuery('#sid2').focus();
    });
  }

  ngAfterViewInit(): void {
    this.tooltipService.init();
  }

  ngOnDestroy(): void {
    this.tooltipService.destroy();
  }

  submitRequest(event: any) {
    event.preventDefault();

    this.requestForm.markAllAsTouched();

    if (this.requestForm.invalid) {
      this.alertService.alert('There are some fields with missing or invalid values, please correct them before you submit.');
      return;
    }

    this.alertService.hide();
    this.loadingMask.show();

    const req = this.requestForm.getRawValue();
    if (this.validationBy !== 'byManager') {
      req.voiceAccessCode = null;
      req.user.userAnswersInfo = req.user.userAnswersInfo.map((qa) => {
        return {
          questionid: qa.questionid,
          answer: this.encryptService.doEncrypt(qa.answer, this.encKey),
          sourceSystem: qa.sourceSystem,
          sid: qa.sid
        };
      });
    }
    req.user.password = this.encryptService.doEncrypt(req.user.password, this.encKey);
    req.pin = this.encryptService.doEncrypt(req.pin, this.encKey);
    req.confirmPin = this.encryptService.doEncrypt(req.confirmPin, this.encKey);

    this.service.resetPIN(req).subscribe((result) => {
      this.loadingMask.hide();

      if (result.errorCode !== 200) {
        this.alertService.alert(null, {code: result.rootCauseCode, ref: result.requestId});
        return;
      }

      this.router.navigate(['/assistance/pin-reset-request/success'], {state: {token: {}}});

    }, error => {
      console.log(error);
      this.alertService.alert(error.error.errorMessage, {code: error.error.rootCauseCode, ref: error.error.requestId});
      this.loadingMask.hide();
    });
  }

  focusOnFirstInvalidInput() {
    let elements = jQuery('input.ng-invalid:visible,select.ng-invalid:visible');
    if (elements.length && !jQuery(elements[0]).is(':focus')) elements[0].focus();
  }

  initOTP() {
    this.otpForm.reset({deliveryMethod: 'sms', sid: this.sid.value});

    this.voiceAccessCode.clearValidators();
    this.voiceAccessCode.updateValueAndValidity();

    this.validationBy = 'byOTP';
    this.entryState = 'otp';
    this.tooltipService.destroy();

    window.setTimeout(() => {
      this.focusOnFirstInvalidInput();
    });
  }

  cancelOTP() {
    this.otpForm.reset({deliveryMethod: 'sms'});

    this.voiceAccessCode.setValidators([Validators.required]);
    this.voiceAccessCode.updateValueAndValidity();

    const sid = this.sid2.value || this.sid.value;
    this.sid.setValue(sid);

    this.validationBy = 'byManager';
    this.entryState = 'pinreset';

    window.setTimeout(() => {
      this.tooltipService.init();
      this.focusOnFirstInvalidInput();
    });
  }

  requestOTP(event: any) {
    event.preventDefault();
    this.otpForm.markAllAsTouched();

    if (this.otpForm.invalid) {
      this.alertService.alert('There are some fields with missing or invalid values, please correct them before you submit.');
      return;
    }

    this.alertService.hide();
    this.loadingMask.show();

    const req = this.otpForm.value;
    req.e164Phone = req.phone.e164Number;

    this.sid.setValue(this.sid2.value);

    this.service.requestOTP(req).subscribe((result) => {
      if (result.errorCode !== 200) {
        this.alertService.alert(null, {code: result.rootCauseCode, ref: result.requestId});
        this.loadingMask.hide();
        return;
      }

      this.entryState = 'pinreset';
      this.formState = 'validateOTP';
      window.setTimeout(() => {
        this.tooltipService.init();
        this.focusOnFirstInvalidInput();
      });
      this.loadingMask.hide();

    }, error => {
      console.log(error);
      this.alertService.alert(error.error.errorMessage, {code: error.error.rootCauseCode, ref: error.error.requestId});
      this.loadingMask.hide();
    });
  }

  validateOtp(event: any) {
    event.preventDefault();
    this.alertService.hide();

    const fields = [
      this.sid,
      this.password,
      this.domain,
      this.mobileAccessCode
    ];

    fields.forEach(field => field.markAsTouched());

    if (fields.some(field => !field.value)) {
      this.alertService.alert('Please fill in all the fields before you submit.');
      return;
    }

    this.loadingMask.show();

    const req = this.requestForm.getRawValue();
    req.user.password = this.encryptService.doEncrypt(req.user.password, this.encKey);

    this.service.validateOTP(req).subscribe(result => {
      if (result.rootCauseCode !== 0) {
        this.alertService.alert(null, {code: result.rootCauseCode, ref: result.requestId});
      } else if (!Array.isArray(result.result) || result.result.length < 3) {
        this.alertService.alert('Incomplete information', {code: 99999, ref: 'Incomplete information'});
      } else {
        this.mobileAccessCode.disable();
        const ans: FormArray = this.getUserAnswersInfo();
        this.qas = result.result;
        this.qas.forEach(qa => {
          ans.push(this.formBuilder.group({
            sid: this.sid.value,
            questionid: qa.id,
            sourceSystem: qa.sourceSystem,
            answer: null
          }, {validators: [Validators.required]}));
        });
        this.formState = 'pinreset';
        window.setTimeout(() => this.focusOnFirstInvalidInput());
      }
      this.loadingMask.hide();
    }, error => {
      console.log(error);
      this.alertService.alert(error.error.errorMessage, {code: error.error.rootCauseCode, ref: error.error.requestId});
      this.loadingMask.hide();
    });
  }

  forceNumbersOnly(event: any) {
    const input = event.target.attributes.getNamedItem('ng-reflect-name').value;
    this.requestForm.get(input).setValue(event.target.value.replace(/[^0-9]+/g, ''), { emitEvent: false });
  }

  get sid(): AbstractControl {
    return this.requestForm.get('user').get('sid');
  }

  get sid2(): AbstractControl {
    return this.otpForm.get('sid');
  }

  get password(): AbstractControl {
    return this.requestForm.get('user').get('password');
  }

  get mobileAccessCode(): AbstractControl {
    return this.requestForm.get('user').get('mobileAccessCode');
  }

  get voiceAccessCode(): AbstractControl {
    return this.requestForm.get('user').get('voiceAccessCode');
  }

  get pin(): AbstractControl {
    return this.requestForm.get('pin');
  }

  get confirmPin(): AbstractControl {
    return this.requestForm.get('confirmPin');
  }

  get domain(): AbstractControl {
    return this.requestForm.get('user').get('domain');
  }

  get phone(): AbstractControl {
    return this.otpForm.get('phone');
  }

  get deliveryMethod(): AbstractControl {
    return this.otpForm.get('deliveryMethod');
  }

  getUserAnswersInfo(): FormArray {
    return this.requestForm.get('user').get('userAnswersInfo') as FormArray;
  }

  getQA(i): AbstractControl {
    const fa: FormArray = this.getUserAnswersInfo();
    return fa !== null ? fa.controls[i].get('answer') : null;
  }
}
